import React from 'react';
import PropTypes from 'prop-types';
import { PaginationArrowRight } from '../custom-icons';

function Pagination({ pageNo, setPageNo, maxPageNo }) {
  return (
    <div className="flex w-full max-w-xs">
      <button
        onClick={() => setPageNo(Math.max(pageNo - 1, 1))}
        className="float-left transform rotate-180 cursor-pointer"
      >
        <PaginationArrowRight className="w-6 h-6 text-secondary" />
      </button>
      <div className="flex-grow text-center font-semiBold">{`${pageNo} of ${maxPageNo}`}</div>
      <button
        onClick={() => setPageNo(Math.min(pageNo + 1, maxPageNo))}
        className="float-right cursor-pointer"
      >
        <PaginationArrowRight className="text-secondary" />
      </button>
    </div>
  );
}

Pagination.propTypes = {
  pageNo: PropTypes.number.isRequired,
  setPageNo: PropTypes.func.isRequired,
  maxPageNo: PropTypes.number,
};

export default Pagination;
