import React from 'react';
import PropTypes from 'prop-types';
import DropdownFilter from './DropdownFilter';

function BlogFiltersDesktop({
  years,
  months,
  topics,
  selectedTopic,
  selectedMonth,
  selectedYear,
  setSelectedMonth,
  setSelectedTopic,
  setSelectedYear,
}) {
  return (
    <div className="items-center justify-center hidden w-full bg-primary-0 md:flex md:h-20">
      <div className="w-full max-w-6xl px-6 text-sm md:flex">
        <div className="w-24 mr-6 leading-6">Filter:</div>
        {topics && setSelectedTopic && (
          <DropdownFilter
            label={selectedTopic.label}
            options={topics}
            onChange={setSelectedTopic}
          />
        )}
        <DropdownFilter
          label={selectedYear.label}
          options={years}
          onChange={setSelectedYear}
        />
        <DropdownFilter
          label={selectedMonth.label}
          options={months}
          onChange={setSelectedMonth}
        />
      </div>
    </div>
  );
}

BlogFiltersDesktop.propTypes = {
  years: PropTypes.array.isRequired,
  months: PropTypes.array.isRequired,
  topics: PropTypes.array,
  selectedTopic: PropTypes.object,
  selectedMonth: PropTypes.object.isRequired,
  selectedYear: PropTypes.object.isRequired,
  setSelectedTopic: PropTypes.func,
  setSelectedMonth: PropTypes.func.isRequired,
  setSelectedYear: PropTypes.func.isRequired,
};

BlogFiltersDesktop.defaultProps = {
  topics: null,
  selectedTopic: null,
  setSelectedTopic: null,
};

export default BlogFiltersDesktop;
